var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    { staticClass: "mt-n6", attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        { ref: "form", staticClass: "w-100 pa-8" },
        [
          _vm.is2FA == false
            ? _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pb-0 mb-0" },
                        [
                          _c(
                            "v-row",
                            { staticClass: "mb-60" },
                            [
                              _c(
                                "v-tabs",
                                {
                                  attrs: {
                                    grow: "",
                                    flat: "",
                                    color: "secondary",
                                  },
                                  model: {
                                    value: _vm.tab,
                                    callback: function ($$v) {
                                      _vm.tab = $$v
                                    },
                                    expression: "tab",
                                  },
                                },
                                [
                                  _c(
                                    "v-tab",
                                    {
                                      key: "1",
                                      staticClass:
                                        "accent lighten-5 taborder-1 font-weight-bolder",
                                      attrs: { href: "#Tab-1" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("AL_ChangePassword")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-tab-item",
                                    {
                                      key: "1",
                                      staticClass: "grey lighten-4",
                                      attrs: { id: "Tab-1", value: "Tab-1" },
                                    },
                                    [
                                      _c(
                                        "v-expansion-panels",
                                        {
                                          attrs: {
                                            accordion: "",
                                            multiple: "",
                                            tile: "",
                                          },
                                          model: {
                                            value: _vm.panel,
                                            callback: function ($$v) {
                                              _vm.panel = $$v
                                            },
                                            expression: "panel",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-expansion-panel",
                                            {
                                              key: "0",
                                              attrs: { id: "1", value: "0" },
                                            },
                                            [
                                              _c(
                                                "v-expansion-panel-header",
                                                {
                                                  staticClass:
                                                    "font-weight-bold py-6 accent lighten-5",
                                                  staticStyle: {
                                                    "min-height":
                                                      "0 !important",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "AL_ChangePassword"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-expansion-panel-content",
                                                { staticClass: "py-5" },
                                                [
                                                  _c("v-text-field", {
                                                    ref: "email",
                                                    staticClass:
                                                      "mb-3 fieldDisabled",
                                                    attrs: {
                                                      id: "email",
                                                      autocomplete: "off",
                                                      error: _vm.errors.email,
                                                      dense: "",
                                                      outlined: "",
                                                      "hide-details": "",
                                                      color: "secondary",
                                                      "append-icon": "email",
                                                      placeholder:
                                                        _vm.$t("FL_Email"),
                                                      label: _vm.$t("FL_Email"),
                                                      disabled: "disabled",
                                                    },
                                                    model: {
                                                      value: _vm.model.email,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.model,
                                                          "email",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "model.email",
                                                    },
                                                  }),
                                                  _c("v-text-field", {
                                                    ref: "password",
                                                    staticClass: "mb-3",
                                                    attrs: {
                                                      id: "password",
                                                      autocomplete: "off",
                                                      error:
                                                        _vm.errors.password,
                                                      dense: "",
                                                      outlined: "",
                                                      "hide-details": "",
                                                      color: "secondary",
                                                      "append-icon":
                                                        _vm.showpassword
                                                          ? "mdi-eye"
                                                          : "mdi-eye-off",
                                                      type: _vm.showpassword
                                                        ? "text"
                                                        : "password",
                                                      placeholder:
                                                        _vm.$t("FL_Password"),
                                                      label:
                                                        _vm.$t("FL_Password"),
                                                    },
                                                    on: {
                                                      "click:append": function (
                                                        $event
                                                      ) {
                                                        _vm.showpassword =
                                                          !_vm.showpassword
                                                      },
                                                    },
                                                    model: {
                                                      value: _vm.model.password,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.model,
                                                          "password",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "model.password",
                                                    },
                                                  }),
                                                  _c("v-text-field", {
                                                    ref: "newpassword1",
                                                    staticClass: "mb-3",
                                                    attrs: {
                                                      id: "newpassword1",
                                                      autocomplete: "off",
                                                      error:
                                                        _vm.errors.newpassword1,
                                                      dense: "",
                                                      outlined: "",
                                                      "hide-details": "",
                                                      color: "secondary",
                                                      "append-icon":
                                                        _vm.newShowpassword
                                                          ? "mdi-eye"
                                                          : "mdi-eye-off",
                                                      type: _vm.newShowpassword
                                                        ? "text"
                                                        : "password",
                                                      placeholder:
                                                        _vm.$t(
                                                          "FL_newpassword1"
                                                        ),
                                                      label:
                                                        _vm.$t(
                                                          "FL_newpassword1"
                                                        ),
                                                    },
                                                    on: {
                                                      "click:append": function (
                                                        $event
                                                      ) {
                                                        _vm.newShowpassword =
                                                          !_vm.newShowpassword
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.model.newpassword1,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.model,
                                                          "newpassword1",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "model.newpassword1",
                                                    },
                                                  }),
                                                  _c("v-text-field", {
                                                    ref: "newpassword2",
                                                    staticClass: "mb-3",
                                                    attrs: {
                                                      id: "newpassword2",
                                                      autocomplete: "off",
                                                      error:
                                                        _vm.errors.newpassword2,
                                                      dense: "",
                                                      outlined: "",
                                                      "hide-details": "",
                                                      color: "secondary",
                                                      "append-icon":
                                                        _vm.newShowpasswordConfirm
                                                          ? "mdi-eye"
                                                          : "mdi-eye-off",
                                                      type: _vm.newShowpasswordConfirm
                                                        ? "text"
                                                        : "password",
                                                      placeholder:
                                                        _vm.$t(
                                                          "FL_newpassword2"
                                                        ),
                                                      label:
                                                        _vm.$t(
                                                          "FL_newpassword2"
                                                        ),
                                                    },
                                                    on: {
                                                      "click:append": function (
                                                        $event
                                                      ) {
                                                        _vm.newShowpasswordConfirm =
                                                          !_vm.newShowpasswordConfirm
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.model.newpassword2,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.model,
                                                          "newpassword2",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "model.newpassword2",
                                                    },
                                                  }),
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass:
                                                        "my-3 d-flex justify-content-center align-items-center",
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "mr-0 pr-0",
                                                          attrs: {
                                                            cols: "auto",
                                                          },
                                                        },
                                                        [
                                                          _c("v-img", {
                                                            attrs: {
                                                              id: "captchaimg",
                                                              height: "106",
                                                              width: "300",
                                                              src: _vm.captchaUrl,
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "placeholder",
                                                                  fn: function () {
                                                                    return [
                                                                      _c(
                                                                        "v-skeleton-loader",
                                                                        {
                                                                          staticClass:
                                                                            "my-auto",
                                                                          attrs:
                                                                            {
                                                                              type: "image",
                                                                              height:
                                                                                "106",
                                                                              width:
                                                                                "300",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  },
                                                                  proxy: true,
                                                                },
                                                              ],
                                                              null,
                                                              false,
                                                              169404245
                                                            ),
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "auto",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                depressed: "",
                                                                icon: "",
                                                                color: "accent",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.ReloadCaptcha()
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "cached"
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-text-field", {
                                                    staticClass: "mb-5",
                                                    attrs: {
                                                      error: _vm.errors.captcha,
                                                      dense: "",
                                                      outlined: "",
                                                      "hide-details": "",
                                                      color: "secondary",
                                                      "append-icon":
                                                        "mdi-shield-check",
                                                      placeholder:
                                                        _vm.$t(
                                                          "FL_SecurityCode"
                                                        ),
                                                      label:
                                                        _vm.$t(
                                                          "FL_SecurityCode"
                                                        ),
                                                    },
                                                    model: {
                                                      value: _vm.model.captcha,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.model,
                                                          "captcha",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "model.captcha",
                                                    },
                                                  }),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        depressed: "",
                                                        block: "",
                                                        color:
                                                          "primary white--text",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.ChangePassword()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "AL_ChangePassword"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "v-container",
                { staticClass: "p-0 px-2" },
                [
                  _c(
                    "v-row",
                    { staticClass: "d-flex justify-content-center" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "10", md: "8", lg: "5" } },
                        [
                          _c(
                            "div",
                            { staticClass: "bg-white border p-5 mt-5" },
                            [
                              _c("h1", { staticClass: "display-3" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("TH_ChangePasswordVerification")
                                  )
                                ),
                              ]),
                              _c("p", { staticClass: "caption" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("IM_EnterSMSVerificationCode"))
                                ),
                              ]),
                              _c("hr"),
                              _c("v-text-field", {
                                staticClass: "mb-3",
                                attrs: {
                                  error: _vm.errors.verificationcode,
                                  dense: "",
                                  outlined: "",
                                  "hide-details": "",
                                  color: "accent",
                                  "append-icon": "lock",
                                  placeholder: _vm.$t("FL_VerificationCode"),
                                  label: _vm.$t("FL_VerificationCode"),
                                },
                                model: {
                                  value: _vm.model.verificationcode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "verificationcode", $$v)
                                  },
                                  expression: "model.verificationcode",
                                },
                              }),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    depressed: "",
                                    block: "",
                                    color: "primary white--text",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.Authenticate()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("AL_Send")))]
                              ),
                              _c("hr"),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "" },
              model: {
                value: _vm.processing,
                callback: function ($$v) {
                  _vm.processing = $$v
                },
                expression: "processing",
              },
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "py-5",
                  attrs: { color: "transparent", dark: "" },
                },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "text-center py-5" },
                    [
                      _c("v-progress-circular", {
                        attrs: { indeterminate: "", size: 50, color: "white" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }